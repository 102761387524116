export const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"] as const;
export const WEEKEND: Day[] = ["Saturday", "Sunday"];
export const COOK = "Cook" as const;
export const SKIP = "Skip" as const;
export const LEFTOVER = "Leftover" as const;
export const mealOptions = [COOK, LEFTOVER, SKIP] as const;
export const YES = "Yes";
export const NO = "No";
export const DATE = "date";
export const DAY = "day";
export const MEAL_TYPE = "mealType";
export const ID = "id";
export const YES_NO_OPTIONS = [YES, NO];
export const DINNER = "dinner";
export const LUNCH = "lunch";
export const LUNCHBOX = "lunchbox";
export const MEAL_TYPES = [LUNCHBOX, LUNCH, DINNER] as const;
export const WITH_LEFTOVERS = "withLeftovers";
export const ADDITIONAL_REQUESTS = "additionalRequests";
export const AVOID_REQUESTS = "ingredientsToAvoid";
export const LUNCHBOX_INSTRUCTIONS = "lunchboxInstructions";
export const LIKE = "like";
export const DISLIKE = "dislike";
export const LATER = "later";
export const PREP_TIME = "prepTime";
export const REACTIONS = [LIKE, DISLIKE] as const;
export const DECISIONS = [LIKE, DISLIKE, LATER] as const;
export const ACTION = "_action";
export const VOTE = "vote";
export const CONFIG = "config";

export const CUISINES = "cuisines";
export const CUISINE_OPTIONS = [
  "Vegetarian",
  "Vegan",
  "Italian",
  "Mexican",
  "Indian",
  "Chinese",
  "Japanese",
  "Thai",
  "Vietnamese",
  "American",
] as const;

export type Reaction = (typeof REACTIONS)[number];
export type Decision = (typeof DECISIONS)[number];

export function isMealType(value: unknown): value is MealType {
  return MEAL_TYPES.includes(value as MealType);
}

export function isReaction(value: unknown): value is Reaction {
  return REACTIONS.includes(value as Reaction);
}

export function isDecision(value: unknown): value is Decision {
  return DECISIONS.includes(value as Decision);
}

export function isDayOfTheWeek(value: unknown): value is Day {
  return daysOfWeek.includes(value as Day);
}

export const DEFAULT_PREP_TIME = {
  [LUNCH]: 20,
  [DINNER]: 30,
  [LUNCHBOX]: 15,
};

export type Day = (typeof daysOfWeek)[number];
export type MealOptionValue = (typeof mealOptions)[number];
export type Meal = {
  recipeId: string;
  title: string;
  description: string;
  isRefrigeratable?: boolean | null;
  reaction?: Reaction | null;
};

export type DailyMenu = {
  [LUNCH]?: Meal;
  [DINNER]?: Meal;
  [LUNCHBOX]?: Meal;
};

export type MealType = (typeof MEAL_TYPES)[number];

export type WeeklyMenu = {
  [key in Day]: DailyMenu;
};

export type PartialWeeklyMenu = {
  [key in Day]?: DailyMenu;
};

export type DailyRequirements = {
  [DAY in Day]: { [LUNCH]: MealOptionValue; [DINNER]: MealOptionValue };
};

export type WeeklyConfig = {
  daily: DailyRequirements;
  [LUNCHBOX]: boolean;
  [AVOID_REQUESTS]?: string;
  [ADDITIONAL_REQUESTS]?: string;
  [LUNCHBOX_INSTRUCTIONS]?: string;
  [WITH_LEFTOVERS]?: boolean;
  [PREP_TIME]?: {
    [LUNCH]: number;
    [DINNER]: number;
  };
  [CUISINES]?: string[];
}; // make GeneratedWeeklyMenu deeply partial

export type MealConfig = {
  [AVOID_REQUESTS]?: string;
  [ADDITIONAL_REQUESTS]?: string;
  [CUISINES]?: string[];
  [PREP_TIME]?: number;
};

export interface ExtendedMeal extends Omit<Meal, "recipeId"> {
  day: Day;
  mealType: MealType;
  recipeId?: string;
}
