import { useFetcher } from "@remix-run/react";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { LIKE } from "~/utils/constants";

export function LikeButton({ recipeId, liked }: { recipeId: string; liked: boolean }) {
  const fetcher = useFetcher({ key: `reaction-${recipeId}` });
  const checked = fetcher.formData ? fetcher.formData.get(LIKE) === "on" : liked;

  return (
    <fetcher.Form
      method="post"
      action={`/recipe/${recipeId}/reaction`}
      onChange={(e) => {
        fetcher.submit(e.currentTarget);
      }}
    >
      <Like checked={checked} />
    </fetcher.Form>
  );
}

function Like({ checked }: { checked: boolean }) {
  return (
    <label>
      <input type="checkbox" name={LIKE} checked={checked} hidden readOnly />
      <span className="flex flex-row btn btn-sm btn-ghost px-2 -ml-2 text-base-content/65">
        {checked ? <IoMdHeart size={18} className="fill-primary" /> : <IoMdHeartEmpty size={18} />}
        Favorite
      </span>
    </label>
  );
}
